
header {
  background-color: $bar-background; //#21AFB8;
  padding-bottom: rem-calc(20px);

  .promotional-banner {
    background-color: #ffff00;
    color: darkmagenta;
    font-size: rem-calc(20px);
    padding-top: rem-calc(10px);
    padding-bottom: rem-calc(10px);
  }

  h1 {
    padding-top: rem-calc(30px);
    color: aqua; //#ffffff;
    font-family: 'Bungee Shade', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: rem-calc(40px);

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      font-size: rem-calc(60px);
    }

    @media screen and (min-width: 64em) {
      font-size: rem-calc(80px);
    }
  }

  p {
    color: $white;
  }

  .menu {
    a {
      font-size: rem-calc(24px);
      font-weight: 700;
      color: $blush_pink;
    }
  }
}


/* HOMEPAGE */

.homepage-intro {
  padding-bottom: rem-calc(30px);
}
.homepage-banner {
  margin-top: rem-calc(30px);
  .fa-star {
    color: $mint; //#FFC11F;
  }
  margin-bottom: rem-calc(30px);
}

.fa-heart {
  color: $pink; // color: red;
}

/* Classes page */

.pricing {
  margin-top: rem-calc(30px);
}
.pricing-structure {

  th {
    font-size: rem-calc(20);
  }

  h6 {
    //margin: 0;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;
  }
}


/* Footer */
footer {

  margin-top: rem-calc(50px);
  padding-top: rem-calc(20px);
  padding-bottom: rem-calc(20px);
  background-color: $bar-background; // #21AFB8;
  color: $white;

  .footer-menu  {
    padding-bottom: rem-calc(40px);
    border-bottom: 2px solid $mint;
    margin-bottom: rem-calc(40px);
    a {
      color: $blush_pink;
      font-size: rem-calc(20px);
      font-weight: 700;
    }

  }

  .social-menu  {

    padding-bottom: rem-calc(40px);
    border-bottom: 2px solid $mint;
    margin-bottom: rem-calc(40px);
    a {
      color: aqua; //#ffffff; //$blush_pink;
      font-size: rem-calc(30px);
      font-weight: 700;
    }

  }



  a {
    //color: $blush_pink;
  }

  .locations,
  .copyright {
    color: $mint;
  }
}

.small-print {
  font-size: rem-calc(14px);
}
